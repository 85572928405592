.nav {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.nav--main {
  margin-top: 5rem;
  font-size: 30px;
  
  @media only screen and (min-width: $bp-medium) {
    font-size: $font-size-nav;
  }
}

.nav--main__item {
  margin-bottom: 30px;

  @media only screen and (min-width: $bp-medium) {
    margin-bottom: 4.5rem;
  }
  
  a {
    color: white;
    display: block;
    line-height: 1;

    &.active,
    &:hover {
      color: $color-medium;
    }
  }
}

.nav--sub {
  margin-top: 3rem;
  margin-bottom: 4.4rem;

  @media only screen and (min-width: $bp-medium) {
    margin-top: 8vh;
    margin-bottom: 0;
  }
}

.nav--sub__item,
.nav--sub__item a {
  cursor: pointer;
  margin-bottom: 1rem;
  color: $color-medium;
  
  &.active,
  &:hover {
    color: $color-dark;
  }
}

.nav--footer {
  a:hover,
  a.active {
    color: $color-medium;
  }
}