.fade-enter main {
  opacity: 0.01;
}

.fade-enter-active main {
  opacity: 1;
  transition: opacity $fx-duration linear;
  transition: opacity $fx-duration-long linear;
}

.fade-exit main {
  opacity: 1;
}

.fade-exit-active main {
  opacity: 0.01;
  transition: opacity $fx-duration linear;
  transition: all $fx-duration-long linear;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: $fx-duration;
}