.container {
  padding: 0 $layout-padding / 2;
  max-width: $layout-max-width;
  position: relative;
  
  @media only screen and (min-width: $bp-medium) {
    padding: 0 $layout-padding;
  }

  &.home {
    max-width: $layout-max-width-home;
  }
}
