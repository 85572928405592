.project__image {
  margin-bottom: 4rem;

  @media only screen and (min-width: $bp-medium) {
    margin-bottom: 9rem;
  }
}

.project__details {
  @media only screen and (min-width: $bp-medium) {
    //width: 500px;
    // height: 60vh;
    // overflow: scroll;
    // position: relative;
  }

  @media only screen and (min-width: $bp-sidebar) {
  }
}

.project__data {
  font-size: $font-size-small;
  transition: opacity 0.3s linear;

  @media only screen and (min-width: $bp-medium) {
    opacity: 1;
  }
}

.fadeOut .project__data {
  @media only screen and (min-width: $bp-medium) {
    opacity: 0;
  }
}

.project__description {
  @media only screen and (min-width: $bp-medium) {
    max-width: 710px;
  }
}

.project__video {
  width: 100%;
  margin-bottom: 4rem;

  &:focus {
    outline: none;
  }
}

h1.project__title {
  margin-bottom: 2.5rem;
  font-size: $font-size-base;
  line-height: 3rem;
  font-weight: normal;

  @media only screen and (min-width: $bp-medium) {
    margin-bottom: 8rem;
    margin-top: 100px;
  }
}

h2.project__title {
  margin-bottom: 4rem;
  line-height: 3rem;
  font-size: $font-size-base;
}

video {
  display: block;

  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.video__container {
  position: relative;
}

.video__play {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;
  border: none;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.video__play:focus,
.video__play:active {
  outline: none;
}
