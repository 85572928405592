.sidebar {
  background-color: $color-light;

  @media only screen and (min-width: $bp-medium) {
    padding: 0 4rem 7.7rem $layout-padding;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 506px;
  }

  header {
    padding: 4rem 0;

    @media only screen and (min-width: $bp-medium) {
      padding: 0;
      height: $layout-headroom;
      display: flex;
      align-items: center;
    }
  }
}

.sidebar__back-icon {
  margin-right: 10px;
}

.sidebar__logo {
  font-size: 2.4rem;
  z-index: 150;
}

@media only screen and (min-width: $bp-medium) {
  main {
    padding-left: 455px;
    padding-bottom: 7.7rem;
  }

  .home main {
    height: 100vh;
  }
}

// @media only screen and (min-width: $bp-medium) {
//   .home {
//     .sidebar {
//       width: 330px;
//     }

//     main {
//       padding-left: 330px;
//       padding-bottom: 7.7rem;
//       height: 100vh;
//     }
//   }

//   .projects {
//     .sidebar {
//       width: 456px;
//     }

//     main {
//       padding-left: 403px;
//     }
//   }

//   .project {
//     .sidebar {
//       width: 656px;
//     }

//     main {
//       padding-left: 603px;
//     }
//   }

//   .default {
//     .sidebar {
//       width: 330px;
//     }

//     main {
//       padding-left: 330px;
//     }
//   }
// }

// @media only screen and (min-width: $bp-sidebar) {
//   .home {
//     .sidebar {
//       width: 506px;
//     }

//     main {
//       padding-left: 455px;
//       padding-bottom: 7.7rem;
//       height: 100vh;
//     }
//   }

//   .projects {
//     .sidebar {
//       width: 656px;
//     }

//     main {
//       padding-left: 603px;
//     }
//   }

//   .project {
//     .sidebar {
//       width: 656px;
//     }

//     main {
//       padding-left: 603px;
//     }
//   }

//   .default {
//     .sidebar {
//       width: 506px;
//     }

//     main {
//       padding-left: 455px;
//     }
//   }
// }
