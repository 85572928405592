.btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: auto;
  display: block;

  &:focus,
  &:active {
    outline: none;
  }
}

.btn--burger,
.btn--close {
  line-height: 0;
  cursor: pointer;
}

.btn--close__container {
  position: fixed;
  left: 0;
  max-width: $layout-max-width;
  width: 100%;
  text-align: right;
  padding: 0 $layout-padding / 2;
  //display: none;
  z-index: 110;
  top: 16px;

  @media only screen and (min-width: $bp-medium) {
    display: block;
    padding: 0 $layout-padding;
    top: auto;
  }

  .btn--close {
    //background-color: $color-light;
    //padding: 5px;
    //display: inline-block;
    float: right;
  }
}

.btn--close__container--mobile {
  @media only screen and (min-width: $bp-medium) {
    display: none;
  }
}

.btn--burger__container {
  padding: 3rem 0;
  background-color: $color-light;
  display: flex;
  justify-content: space-between;
  z-index: 20;
  position: relative;

  @media only screen and (min-width: $bp-medium) {
    padding: 0;
  }
}


