.buero {
  h2 {
    margin-top: 7rem;
    margin-bottom: 5rem;
    line-height: 3rem;
    
    @media only screen and (min-width: $bp-medium) {
      margin-top: 15rem;
      margin-bottom: 10rem;
    }
  }
}

.buero__mood {
  margin-bottom: 12.5rem;
  h2 {
    margin-top: 0;
  }
  
  @media only screen and (min-width: $bp-medium) {
    //margin-bottom: 32.5rem;
    margin-bottom: 17.7rem;
  }
}

#personen {
  h2 {
    margin-top: 0;
  }
}

.buero__person {
  &:last-child {
    margin-top: 10rem;
    
    @media only screen and (min-width: $bp-medium) {
      margin-top: 17.7rem;
    }
  }

  img {
    margin-bottom: 2rem;
    margin-top: 2rem;
    // max-width: 600px;
    
    @media only screen and (min-width: $bp-medium) {
      margin-bottom: 0;
    }
    
    @media only screen and (min-width: 1200px) {
      margin-top: 0;
    }
  }
}

.buero__person--bathke {
  .col:first-child {
    order: 2;
    
    @media only screen and (min-width: 1200px) {
      order: 1;
    }
  }

  .col:last-child {
    order: 1;
    
    @media only screen and (min-width: 1200px) {
      order: 2;
    }
  }
}

#veroeffentlichungen {
  h2 {
    margin: 23rem 0 11.5rem 0;
  }
}

#auszeichnungen,
#wettbewerbe {
  h2 {
    margin: 15rem 0 11.5rem 0;
  }
}

.buero__gap {
  margin-bottom: 10rem;
}

.buero__employee__image {
  margin-bottom: 2.5rem;
}

.buero__footer {
  margin-top: 15rem;
}