.flyout {
  position: fixed;
  height: 100%;
  padding: 0 $layout-padding 7.7rem $layout-padding;
  display: flex;
  flex-direction: column;
  background-color: black;
  transform: translateX(-100%);
  transition: all $fx-duration ease-out;
  top: 0;
  left: 0;
  color: white;
  z-index: 20000;
  width: 100%;
  
  @media only screen and (min-width: 500px) {
    width: 466px;
  }

  &.show {
    transform: translateX(0);
  }

  header {
    padding: 4rem 0;

    @media only screen and (min-width: $bp-medium) {
      padding: 3rem 0;
      height: $layout-headroom;
      display: flex;
      align-items: center;
    }

    a {
      color: white;
    }
  }

  .flyout__footer {
    border-top: 1px solid $color-light;
    padding-top: 2.5rem;
    font-size: $font-size-small;
    margin-top: auto;

    @media only screen and (min-width: $bp-medium) {
      border-top: none;

      .flyout__address {
        display: none;
      }

      nav {
        display: block,
      }
    }

    a {
      color: white;
    }
  }
}