// @font-face {
//   font-family: 'roboto';
//   src: url('/fonts/Roboto-Regular.ttf') format('truetype');
//   font-weight: normal;
// }
// @font-face {
//   font-family: 'roboto';
//   src: url('/fonts/Roboto-Bold.ttf') format('truetype');
//   font-weight: bold;
// }

@font-face {
  font-family: 'roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'),
       url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'),
       url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  font-size: 50%;

  @media only screen and (min-width: $bp-medium) {
    font-size: 58%;
  }

  @media only screen and (min-width: $bp-large) {
    font-size: 62.5%;
  }
}

body {
  font-family: $font-family;
  font-size: $font-size-base;
  background-color: $color-light;
  color: $color-dark;
}

h1 {
  font-weight: bold;
  font-size: $font-size-h1-mobile;
  line-height: 4.5rem;
  margin: 0;
  
  @media only screen and (min-width: $bp-medium) {
    line-height: 7.0rem;
    font-size: $font-size-h1;
  }
}

h2 {
  font-weight: bold;
  //font-size: $font-size-h2-mobile;
  line-height: 5.0rem;
  margin: 0;
  
  @media only screen and (min-width: $bp-medium) {
    font-size: $font-size-base;
  }
}

h3 {
  font-weight: normal;
  font-size: $font-size-h3;
  line-height: 1.2;
  margin: 0;
}

a {
  color: $color-dark;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

img {
  max-width: 100%;
  width: 100%;
}

main {
  padding-bottom: 4.5rem;
  padding-top: 3rem;
  position: relative;
  
  @media only screen and (min-width: $bp-medium) {
    padding-top: $layout-headroom;
  }
  
  h1 {
    margin: 5rem 0 3rem 0;

    @media only screen and (min-width: $bp-medium) {
      margin: 0 0 16rem 0;
    }
  }
}

.home main .content {
  height: 100vh;
  z-index: 1;
  max-height: 100%;
}

footer {
  font-size: $font-size-small;
  margin-top: auto;
  display: none;

  @media only screen and (min-width: $bp-medium) {
    display: block;
  }

  p {
    margin-bottom: 2rem;
  }
}

.copyright {
  font-size: $font-size-small;
  color: $color-medium;
  margin-top: 25rem;
}

@media only screen and (max-width: $bp-small) {
  .grid-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .grid-row div {
    padding-left: 0;
    padding-right: 0;
  }
}

.sticky {
  z-index: 100;
}

.contact__address {
  margin-top: 8vh;
  color: $color-medium;

  a {
    color: $color-medium;

    &:hover {
      color:  $color-dark;
    }
  }
}