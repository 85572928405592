.teaser {
  margin-bottom: 4rem;

  @media only screen and (min-width: $bp-medium) {
    margin-bottom: 5.0rem;
  }
  
  a {
    display: block;
  }
}

.teaser__title {
  text-align: right;
  margin-top: 1.5rem;

  @media only screen and (min-width: $bp-medium) {
    margin-top: 2.0rem;
  }
}

@media only screen and (min-width: $bp-medium) {
  .projects main .content {
    max-width: 1010px;
  }
}