.table__header {
  display: flex;
  font-weight: bold;
  border-bottom: 1px solid $color-dark;

  div {
    flex: 0.5;
    padding-bottom: 3px;
  }

  div:first-child {
    flex: 0.2;
  }

  div:last-child {
    flex: 0.3;
  }
}

.table__body {
  .row {
    display: flex;
    border-bottom: 1px solid $color-dark;
    padding: 16px 0;
    flex-direction: column;

    @media only screen and (min-width: $bp-medium) {
      flex-direction: row;
    }
  }

  div {
    flex: 0.5;
  }

  div:first-child {
    flex: 0.2;
  }

  div:last-child {
    flex: 0.3;
  }
}

.table__body--publications {
  div:first-child {
    flex: 0.5;
  }

  div:last-child {
    flex: 0.5;
  }
}

.table__body--awards {
  div {
    flex: 1;
  }

  div:first-child {
    flex: 1;
  }

  div:last-child {
    flex: 1;
  }
}

.table__body--contests {
  div {
    flex: 0.7;
  }

  div:first-child {
    flex: 0.1;
  }

  div:last-child {
    flex: 0.2;
  }
}

.table__body--cv {
  .row {
    div {
      flex: 0.3;
    }
    
    div:last-child {
      flex: 0.7;
    }
  }
}
