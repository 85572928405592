.news__item {
  margin-bottom: 6.8rem;
}

.news__image {
  margin-bottom: 2.5rem;
}

.news__date {
  margin-bottom: 1.5rem;
  color: $color-medium;
  font-size: $font-size-small;
  display: block;
}

.news__headline {
  font-size: $font-size-base;
  line-height: 3rem;
}