// FONT
$font-family: 'Roboto', sans-serif;
$font-size-base: 2.0rem;
$font-size-h1: 2.0rem; //5.4rem;
$font-size-h1-mobile: 2.0rem; //3.5rem;
$font-size-h2: 3.5rem;
$font-size-h2-mobile: 3.5rem;
$font-size-h3: $font-size-base;
$font-size-nav: 5.4rem;
$font-size-small: 1.5rem;

// COLOR
$color-light: #FFFFFF;
$color-medium: #A3A3A3;
$color-dark: #3C3C3C;

// LAYOUT
$layout-max-width: 1871px;
$layout-max-width-home: 2500px;
$layout-padding: 5.6rem;
$layout-headroom: 24vh;

// BREAKPOINT
$bp-small: 600px;
$bp-sidebar: 1400px;
$bp-medium: 1000px;
$bp-large: 1800px;

// EFFECT
$fx-duration: 0.3s;
$fx-duration-long: 1s;